import "./App.scss";

function App() {
  return (
    <div className="App">
      <header
        className="App-header"
        style={{ textAlign: "center" }}
      >
        <h1>Steve Portfolio</h1>
      </header>
    </div>
  );
}

export default App;
